import React, { useState, useEffect } from "react"
import { Modal, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"

function Popup() {
  const [lgShow, setLgShow] = useState(false)
  const handleClose = () => setLgShow(false)
  const handleShow = () => setLgShow(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("This will run after 3 second!")
      handleShow()
    }, 3000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <div>
      <Modal size="lg" show={lgShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="text-space text-red">
              <strong>FACEBOOK FEED</strong>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Helmet>
            <script
              async
              defer
              crossorigin="anonymous"
              src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v6.0"
            />
          </Helmet>
          <div
            class="fb-page"
            data-href="https://www.facebook.com/StompinGroundz719/"
            data-tabs="timeline"
            data-width="800"
            data-height="500"
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="true"
          >
            <blockquote
              cite="https://www.facebook.com/StompinGroundz719/"
              class="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/StompinGroundz719/">
                Stompin&#039; Groundz
              </a>
            </blockquote>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default Popup
