import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import MySwiper from "../components/swiper/swiper"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import Form from "../components/form/form"
import "react-lazy-load-image-component/src/effects/blur.css"
// import tstl1 from "../images/1.jpg"
// import tstl2 from "../images/2.jpg"
// import tstl3 from "../images/3.jpg"
// import Modal from "../components/modal/modal"
import friends from "../images/cafe.jpeg"
import diverse from "../images/cafe2.jpeg"
import cup from "../images/cup.png"
import Popup from "../components/popup"

class IndexPage extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title
		const siteDescription = this.props.data.site.siteMetadata.description
		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div
					className="container-fluid hero"
					style={{
						backgroundPosition: "center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						backgroundImage:
							"url(https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/k-170-chim-1731.jpg?auto=format&bg=transparent&con=3&cs=srgb&dpr=1&fm=jpg&ixlib=php-3.1.0&mark=rawpixel-watermark.png&markalpha=90&markpad=13&markscale=10&markx=25&q=75&usm=15&vib=3&w=1000&s=544565dd46c32140a6e45c88ea3fa2d6)",
					}}
				>
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h3 className="text-white text-center text-space">
									DAILY DOSE OF
								</h3>
								<h1 className="text-white text-center text-atf text-space">
									<strong>CULTURE, COMMUNITY, COFFEE, & COCKTAILS</strong>
								</h1>
							</div>
						</div>
					</div>
				</div>

				<div className="about">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-6">
								<div className="who-we-are-description">
									<h1 className="text-space text-red">
										<strong>WHO WE ARE</strong>
									</h1>
									<p className="text-left">
										{" "}
										<Popup />
										<strong>
											A long time resident of Colorado Springs decided she would
											wait no more for the things in here community to change
											for the better. After having countless meetings about her
											neighborhood outside of her neighborhood, Juelz Ramirez
											realized the need for a third place like no other. One in
											the heart of Southeast Colorado Springs, so the most
											diverse population of Colorado Springs can conveniently
											feel warm and welcomed to showcase their talents, make
											business deals, and to simply be.
										</strong>
									</p>
									<p className="text-left">
										<strong>
											Juelz saw an opportunity to bring her vision to life when
											Solid Rock Community Development Corporation incorporated
											a coffee shop in their plans for The Hub at Mission Trace.
											Having never run a business before, she enrolled in the
											Entrepreneurial Season Program with Thrive Networks where
											much of what would become Stompin' Groundz was devised.
											After completing the 10 month course in November of 2019
											she was ready to hit the ground running with crowdfunding,
											finalizing floor plans, and gathering community input.
											Follow us on social media to see how the rest of the story
											unfolds.
										</strong>
									</p>
								</div>
							</div>
							<div className="col-md-6">
								<div className="image whoweare-img mr-2">
									<img className="img" src={friends} alt="Who We Are" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mission">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h3 className="text-white text-center text-space">
									Mission (The Stompin' Groundz Pledge to the People)
								</h3>
								<p className="text-center text-white missionvision-p">
									<strong>
										We will nourish the development of identity and culture in
										our community by providing a place of interaction,
										innovation, and inspirations.
									</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="about">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-6">
								<div className="image whereweare-img">
									<img className="img" src={diverse} alt="Where We Are" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="where-we-are-description">
									<h1 className="text-space text-red text-left">
										<strong>WHERE&nbsp;WE ARE</strong>
									</h1>
									<p className="text-left">
										<strong>
											In 2018, the Urban Land Institute conducted a study and
											made strategic recommendations for Southeast Colorado
											Springs; one being to create a central gathering space for
											the community. With this information Solid Rock CDC
											leveraged funding to begin the plans for phase one of “The
											Hub”at the Mission Trace Shopping Center (Academy Blvd
											&amp; Hancock Expy). This plan included a coworker space,
											a large multipurpose room, and a coffee shop.
										</strong>
									</p>
									<p className="text-left">
										<a
											href="https://drive.google.com/file/d/1PjnbOlCWFp1hBF2w1dSSWPgSXboS9MEP/view?usp=sharing"
											target="_blank"
											className="hbtn hb-fill-right-br"
											rel="noopener noreferrer"
										>
											ULI Report
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="vision">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h3 className="text-white text-center text-space">
									OUR VISION
								</h3>
								<p className="text-center text-white missionvision-p">
									<strong>
										Community as the driving force of innovation in our
										neighborhood.
									</strong>
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="contact">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-6">
								<h1 className="text-space text-red">
									<strong>VISIT US</strong>
								</h1>
								<h3 className="h-mobile">
									<strong>719-203-1027</strong>
								</h3>
								<h3 className="h-mobile">
									<strong>
										<a
											className="link-color"
											href="mailto:info@stompingroundz719.com"
										>
											info@stompingroundz719.com
										</a>
									</strong>
								</h3>
								<div className="social-icons text-left">
									<a
										href="https://twitter.com/StompinGroundz_"
										target="_blank"
										rel="noopener noreferrer"
									>
										<i className="icon fa fa-twitter" />
									</a>
									<a
										href="https://www.facebook.com/StompinGroundz719/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<i className="icon fa fa-facebook" />
									</a>
									<a
										href="https://www.instagram.com/stompingroundz_/"
										target="blank_"
										rel="noopener noreferrer"
									>
										<i className="icon fa fa-instagram" />
									</a>
								</div>

								<div className="feature-map mt-5">
									<iframe
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.921789121907!2d-104.75805558465281!3d38.78842727958747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x871343fe9bd74ab1%3A0xb6ff130d6247076a!2s3001%20S%20Academy%20Blvd%2C%20Colorado%20Springs%2C%20CO%2080916%2C%20USA!5e0!3m2!1sen!2sph!4v1582770328919!5m2!1sen!2sph"
										title="Map"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="container">
									<div className="row">
										<h1 className="text-space text-red">
											<strong>CONTACT&nbsp;US</strong>
										</h1>
										<div className="contact-form">
											<Form
												form_name="Stomping Groundz"
												form_id="5e37d993b863b3038594e333"
												form_classname="form-newsletter"
											>
												<div className="row justify-content-center">
													<div className="col-md-6">
														<div className="form-group">
															<input
																className="form-control is-invalid c-form"
																type="text"
																id="name"
																name="Name"
																placeholder="Name"
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<input
																className="form-control is-invalid c-form"
																type="email"
																name="email"
																id="email"
																placeholder="Email"
															/>
														</div>
														<small className="form-text text-muted">
															Please enter a correct Email Address
														</small>
													</div>
													<div className="col-md-12">
														<div className="form-group ">
															<input
																className="form-control is-invalid c-form"
																type="text"
																name="subject"
																id="subject"
																placeholder="Subject"
															/>
														</div>
													</div>
													<div className="col-md-12">
														<div className="form-group">
															<textarea
																className="form-control c-form"
																name="message"
																placeholder="Message"
																id="message"
																rows="14"
															/>
														</div>
													</div>
													<div className="form-group col-md-12">
														<div className="webriq-recaptcha" />
													</div>
													<div className="form-group col-md-12 text-center">
														<button
															className="hbtn hb-fill-right-br"
															rel="noopener noreferrer"
															type="submit"
															id="SendMessage"
														>
															Submit
														</button>
													</div>
												</div>
											</Form>
										</div>
									</div>
								</div>
								<div className="rosebg">
									<img src={cup} alt="Cup" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default IndexPage

export const indexPageQuery = graphql`
	query indexPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
